/* eslint-disable prettier/prettier */
import React from "react"
import { Provider } from "react-redux"
import { createStore, applyMiddleware, compose } from "redux"
import reducers from "../reducer"
import ReduxThunk from "redux-thunk"
import { DomainProvider } from "@context/DomainContext"
import { PrefixProvider } from "@context/PrefixContext"

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose

const createReduxStore = () =>
  createStore(reducers, composeEnhancers(applyMiddleware(ReduxThunk)))

const isBrowser = typeof window !== "undefined"
let domain = "de"
if (isBrowser) {
  const validSubdomains = ["at", "de"]
  domain = process.env.GATSBY_DOMAIN || window.location.origin.slice(-2)
  domain = domain !== "at" && domain !== "de" ? "de" : domain
  let currentDomain = window.location.origin.split(".").pop()

  if (validSubdomains.includes(currentDomain)) domain = currentDomain
} else {
  domain = process.env.GATSBY_DOMAIN
}

const wrapper = ({ element }) => (
  <Provider store={createReduxStore()}>
    <DomainProvider value={domain}>
      <PrefixProvider value={process.env.GATSBY_DEPLOYFOLDER}>
        {element}
      </PrefixProvider>
    </DomainProvider>
  </Provider>
)

export default wrapper
